import React from "react"
import SiteLink from "../bits/siteLink"
import styled from "styled-components"
import PropTypes from "prop-types"

const Wrapper = styled.div`
  padding: 0.4rem var(--var-padding-s);
  //text-transform: uppercase;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: var(--transition);
  //border-radius: 10px;
  //margin: 15px;
  background: var(--color-black);

  p {
    font-family: var(--font-primary);
    font-weight: var(--font-weight-regular);
    margin-bottom: 0;
    color: var(--color-background);
  }

  &:hover {
    & > * {
      color: var(--color-accent-strong);

    }
  }
`

const DemoButton = ({ href, text }) => {
  return (
    <SiteLink to={href} target="_blank" className="noeffect" rel="noopener">
      <Wrapper>
        <p>{text}</p>
      </Wrapper>
    </SiteLink>
  )
}

export default DemoButton

DemoButton.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string,
}

DemoButton.defaultProps = {
  text: "Show More",
}
