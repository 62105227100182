import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
const MyLink = styled(Link)`
  //border-bottom: 1.5px dashed var(--color-grey-500);
  background: linear-gradient(
      to bottom,
      transparent 62%,
      var(--color-accent-medium) 0
    )
    left center/0% 75% no-repeat;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
`

const ActiveLink = {
  fontWeight: 600,
}

const SiteLink = ({
  children,
  to,
  activeClassName,
  partiallyActive,
  ...other
}) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to)

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <MyLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        activeStyle={ActiveLink}
        {...other}
      >
        {children}
      </MyLink>
    )
  }
  return (
    <a href={to} {...other}>
      {children}
    </a>
  )
}

export default SiteLink
