import React from "react"
import styled from "styled-components"
import SiteLink from "../../bits/siteLink"
import Boxed from "../../bits/box"
import Fade from "react-reveal/Fade"
import {
  faTwitter,
  faMedium,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons"
import Icon from "./Icon"

const Container = styled.section`
  display: block;
  margin-top: 1rem;
  @media only screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1.5rem;
  }
`

const Section = styled.div`
  display: block;
`

const Content = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 24px;
`

const IconList = styled.div`
  display: flex;
  flex-direction: row;
`

const FooterLabel = styled.h6`
  font-weight: bold;
`

const FooterItems = () => {
  return (
    <Boxed size="large">
      <Container>
        <Fade bottom>
          <Section>
            <FooterLabel>Let's Chat</FooterLabel>
            <Content>
              Email us
              <SiteLink to="mailto:hello&#64;geckogovernance.com">
                {" "}
                <b>hello&#64;geckogovernance.com</b>
              </SiteLink>
              <br />
              Call us <b>+ 353 (0) 42 941 9675</b>
            </Content>
          </Section>
          <Section>
            <FooterLabel>Thought Leadership</FooterLabel>
            <Content>
              We regularly write on the subject surrounding tech and business so
              catch us on{" "}
              <SiteLink
                to="https://medium.com/@GECKOGovernance"
                target="_blank"
                rel="noopener"
              >
                <b>Medium</b>{" "}
              </SiteLink>
              and{" "}
              <SiteLink
                to="https://ie.linkedin.com/company/geckogovernance"
                target="_blank"
                rel="noopener"
              >
                <b>LinkedIn</b>
              </SiteLink>
            </Content>
          </Section>
          <Section>
            <FooterLabel>Follow us</FooterLabel>
            <Content>
              Follow our social media and see what we are up to.
              <IconList>
                <Icon
                  to="https://ie.linkedin.com/company/geckogovernance"
                  target="_blank"
                  icon={faLinkedin}
                  title="Linkedin"
                />
                <Icon
                  to="https://twitter.com/GECKOgovernance"
                  target="_blank"
                  icon={faTwitter}
                  title="Twitter"
                />
                <Icon
                  to="https://medium.com/@GECKOGovernance"
                  target="_blank"
                  icon={faMedium}
                  title="Medium"
                />
              </IconList>
            </Content>
          </Section>
        </Fade>
      </Container>
    </Boxed>
  )
}

export default FooterItems
