import React, { useState } from "react"
import styled from "styled-components"
import NavbarLinks from "./navItems"
import NavbarLinksMobile from "./navItemsMobile"
import Logo from "./logo"
import {Cross as Hamburger} from "hamburger-react";

const Navigation = styled.nav`
  height: 80px;
  display: flex;
  opacity: 0.98;
  background-color: #fff;
  top: 0vh;
  position: sticky;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 5vw;
  z-index: 2;
  align-self: center;
  box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.1), 0 0px 15px 0 rgba(0, 0, 0, 0.1);

  @media (max-width: 800px) {
    position: sticky;
    height: 80px;
    top: 0vh;
    left: 0;
    right: 0;
    left: 0;
  }
`

const Navbox = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  background-color: #fff;
  //position:sticky;

  @media (max-width: 800px) {
    flex-direction: column;
    position: fixed;
    width: 100%;
    justify-content: flex-start;
    padding-top: 10vh;
    //background-color: #fff;
    transition: all 0.3s ease-in;
    font-size: var(--font-size-l);
    //here is the top adjuster for menu
    top: 78px;
    left: ${props => (props.open ? "-100%" : "0")};
  }
`
const HamburgerWrapper = styled.div`
  display: none;
  margin-top: 15px;
  @media (max-width: 800px) {
    display:flex;
  }
`


const LogoContainer = styled.div`
  min-height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const LogoTitle = styled.h4`
  //font-family: var(--font-primary);
  margin-top: 0px;
  //font-size:25px;
  //font-weight: 600;
  margin-bottom: 0px;
  letter-spacing: 3px;
`
const LogoTitleSmall = styled.p`
  margin-bottom: 0px;
  margin-top: 0px;
  font-weight: 400;
  font-family: var(--font-primary);
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
`

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const Log = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  //align-items: center;
  //height: 5vh;
  width: 60px;
  //background-color: red;
`
const Navbar = () => {
  const [navbarOpen, setNavbarOpen] = useState(false)


  return (
    <Navigation>
      <LogoWrapper>
        <Log>
          <Logo />
        </Log>
        <LogoContainer>
          <LogoTitle>GECKO</LogoTitle>
          <LogoTitleSmall>Governance</LogoTitleSmall>
        </LogoContainer>
      </LogoWrapper>
        <HamburgerWrapper>
          <Hamburger direction="right" color="#006cff" toggled={navbarOpen} toggle={setNavbarOpen}/>
        </HamburgerWrapper>
      {navbarOpen ? (<NavbarLinksMobile/>) : 
      (
        <Navbox open>
          <NavbarLinks />
        </Navbox>
      )}
    </Navigation>
  )
}

export default Navbar
