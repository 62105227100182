import React from "react"
import styled from "styled-components"
import SiteLink from "../../bits/siteLink"
import DemoButton from "../../bits/demoButton"
import Fade from "react-reveal/Fade"




const NavLink = styled(SiteLink)`
  font-family: var(--font-primary);
  display: inline-block;
  white-space: nowrap;
  margin: 0 2vw;

  @media (max-width: 800px) {
    padding: 10px 0;

    &:hover: {
      border-bottom: none;
    }
  }
`

const Navbox = styled.div`
@media (max-width: 800px) {
  display:flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  justify-content: flex-start;
  align-items:center;
  padding-left: 10px;
  background-color: #fff;
  transition: all 0.3s ease-in;
  font-size: var(--font-size-l);
  height: 100vh;
  top: 78px;
  left: 0;
}
`


const NavbarLinksMobile = () => {
  return (
    <Navbox>
        <Fade top>
        <NavLink to="/about">About us</NavLink>
        <NavLink to="/partners">Partners</NavLink>
        <NavLink to="/blog">Blog</NavLink>
        <NavLink to="/usecases/">Use Cases</NavLink>
        <DemoButton text="Contact" href="/#contact" />
        </Fade>       
    </Navbox>
  )
}

export default NavbarLinksMobile
