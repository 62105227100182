import React from "react"
import styled from "styled-components"
import SiteLink from "../../bits/siteLink"
import DemoButton from "../../bits/demoButton"



const NavLink = styled(SiteLink)`
  font-family: var(--font-primary);
  display: inline-block;
  white-space: nowrap;
  margin: 0 2vw;

  @media (max-width: 800px) {
    padding: 20px 0;
    //font-size: 1.5rem;

    &:hover: {
      border-bottom: none;
    }
  }
`

const DropNavLink =styled(NavLink)`
  margin: 1vw 2vw;
`

const DropImageLink =styled(NavLink)`
  margin: 1vw 0 0 0;
  background: none;
`

const DropContainer = styled.div`
  position: relative;
  display: inline-block;
`
const DropButton = styled.div`
  font-family: var(--font-primary);
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  margin: 0 1vw;

  @media (max-width: 800px) {
    padding: 20px 0;
    &:hover: {
      border-bottom: none;
    }
  }
`

const DropContent = styled.div`
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  border: 2px solid #060b1f;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.2);
  z-index: 0;
  padding-bottom: 15px;

  ${DropContainer}:hover & {
    display: block;
    @media (max-width: 800px) {
      width:150px;
      height:200px;
    }
  }
 
`

const DropImageContent = styled.div`
display: none;
position: absolute;
background-color: #fff;
min-width: 160px;
border: 2px solid #060b1f;
box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.2);
z-index: 0;
padding-bottom: 15px;

${DropContainer}:hover & {
  display: flex;
  @media (max-width: 800px) {
    width:150px;
    height:200px;
  }
}
`

const ImageLinkContainer = styled.div`
display:flex;
flex-direction:column;
margin: 1vw;
`

const NavImage = styled.img`
  height: 100px;
  `
const NavbarLinks = () => {
  return (
    <>
      <NavLink to="/usecases">Use Cases</NavLink>

      <DropContainer>
        <DropButton>
          Company 
          &nbsp;
          <svg width="8" height="6" viewBox="0 0 8 6" class="css-1vkg3ei"><path d="M4 6L0.535898 0L7.4641 0L4 6Z" fill="currentColor"></path></svg>
        </DropButton>
        <DropContent>
          <DropNavLink to="/about">About us</DropNavLink>
          <DropNavLink to="/partners">Our Partners</DropNavLink>
        </DropContent>
      </DropContainer>

      <DropContainer>
        <DropButton>
          Resources
          &nbsp;
          <svg width="8" height="6" viewBox="0 0 8 6" class="css-1vkg3ei"><path d="M4 6L0.535898 0L7.4641 0L4 6Z" fill="currentColor"></path></svg>
        </DropButton>
        <DropContent>
          <DropNavLink to="/blog">Blog</DropNavLink>
          <DropNavLink to="/download">Downloads</DropNavLink>
          <DropNavLink to="/careers">Careers</DropNavLink>
        </DropContent>
      </DropContainer>

      <DemoButton text="Contact" href="/#contact" />
    </>
  )
}

export default NavbarLinks
