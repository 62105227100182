/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Navbar from "../components/Header/navbar"
import "../layout/index.css"
//import Topbar from "../components/Topbar/topbar"
import Footer from "../components/Footer/footer"
// import CookieConsent from "react-cookie-consent"
import Headroom from 'react-headroom'
import { Helmet } from "react-helmet"

const Layout = ({ children }) => {
  // style={{
  //   margin: `0 auto`,
  //   padding: `0 1.0875rem 1.45rem`,
  // }}
  // padding: `0 1.0875rem 1.45rem`
  return (
    <>
      {/* <Topbar topTitle={"Request a Demo"} /> */}
      <Helmet>
        <meta name="GECKO Logo" property="og:image" content="https://gecko-public.s3.eu-west-1.amazonaws.com/website-public-asset/gecko-icon.png" />
      </Helmet>
      <Headroom>
      <Navbar />
      </Headroom>

      <main>{children}</main>
      {/* <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="gatsby-gdpr-google-analytics"
        style={{
          background: "#060b1f",
          color: "#fff",
          fontSize: "14px",
          display: "block",
        }}
        buttonStyle={{ background: "#006cff", color: "#fff", fontSize: "15px" }}
      >
        We use cookies to ensure that we give you the best experience on our
        website. If you continue to use this site we will assume that you are
        happy with it. If you wish please read the full
        <SiteLink to="/privacy-policy">
          {" "}
          <b>privacy statement.</b>
        </SiteLink>
      </CookieConsent> */}
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
